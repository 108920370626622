<div class="board">
	<div class="card-content">
		<div class="wrapper-figure text-center mt-5">
			<figure class="logo mb-3">
				<img
					src="/assets/images/logo.png"
					draggable="false"
					alt="Clínica Rascovski"
					title="Clínica Rascovski"
				/>
			</figure>
			<figure>
				<img
					src="/assets/images/clinica-rascovski-gold.png"
					draggable="false"
					alt="Clínica Rascovski"
					title="Clínica Rascovski"
				/>
			</figure>
		</div>
		<div>
			<h2>Obrigado(a)!</h2>
			<h3>
				Sua participação é de extrema importância para nós, pois nos
				auxilia a compreender melhor suas necessidades e proporcionar um
				tratamento personalizado e eficiente.
			</h3>
			<h3>
				Agora, pedimos que aguarde um pouco mais, pois os próximos
				passos envolvem o processamento das informações e o agendamento
				dos procedimentos necessários.
			</h3>
		</div>
	</div>
</div>
