import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { GenericValidatorService } from '../../../services/validators/generic-validator.service'

@Component({
	selector: 'app-personal-data-form',
	templateUrl: './personal-data-form.component.html',
	styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent implements OnInit {
	@Input() documentValue: any = ''
	@Input() initialValue: any = null
	@Input() hasPersonalId: boolean = false
	@Output() emmitEventForm: EventEmitter<any> = new EventEmitter()
	@Output() emmitEventId: EventEmitter<any> = new EventEmitter()
	form: FormGroup

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			name: [
				this.initialValue && this.initialValue.name
					? this.initialValue.name
					: null,
				[Validators.required],
			],
			email: [
				this.initialValue && this.initialValue.email
					? this.initialValue.email
					: null,
				[Validators.required],
			],
			cpf: [
				this.initialValue && this.initialValue.cpf
					? this.initialValue.cpf
					: null,
				[Validators.required, GenericValidatorService.isValidCpf],
			],
			phone: [
				this.initialValue && this.initialValue.phone
					? this.initialValue.phone
					: null,
				[Validators.required],
			],
		})
	}

	ngOnInit(): void {}

	onSubmit() {
		this.emmitEventForm.emit(this.form.value)
	}
}
