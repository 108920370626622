import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import {
	PersonalDataJourneyBody,
	PillarJourneyBody,
} from '../../models/journey'

@Injectable({
	providedIn: 'root',
})
export class JourneyService {
	constructor(private http: HttpClient) {}

	private setParams(personalId: string): any {
		let params: any = {}

		if (personalId) {
			params.personal_id = personalId
		}

		return params
	}

	getJourney(journeyId: string, callbacks: Callbacks): void {
		this.http
			.get<any>(`${environment.api}/${journeyId}/`)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callbacks.fnSuccess(data)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}

	sendFeedback(journeyId: string, data: any, callbacks: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/${journeyId}/patch/`, data)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callbacks.fnSuccess(data)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}

	saveDataUser(code: string, data: any, callbacks: Callbacks): void {
		this.http
			.patch<any>(`${environment.api}/${code}/patch/`, data)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callbacks.fnSuccess(data)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}

	saveDataField(data: any, callbacks: Callbacks): void {
		this.http
			.post<any>(`${environment.api}/`, data)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callbacks.fnSuccess(data)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}

	savePillarJourney(
		journeyId: string,
		personalId: string = '',
		body: PillarJourneyBody,
		callbacks: Callbacks
	): void {
		const params = this.setParams(personalId)

		this.http
			.post<any>(
				`${environment.api}/journey/${journeyId}/response/`,
				body,
				{ params }
			)
			.pipe(
				finalize(() => {
					if (callbacks.fnFinalized) callbacks.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callbacks.fnSuccess(data)
				},
				error: (error) => {
					callbacks.fnError(error)
				},
			})
	}
}
