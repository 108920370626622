<div class="modal-body">
<!--	<app-form-default-->
<!--		[templateEdit]="true"-->
<!--		title="Criação de usuário"-->
<!--		(emmitEventForm)="submit($event)"-->
<!--		[hasFilesUser]="initialData"-->
<!--	></app-form-default>-->
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-white me-2" (click)="close()">
		Cancel
	</button>
	<!--	<button-->
	<!--		type="button"-->
	<!--		class="btn btn-xs btn-primary-ghr"-->
	<!--		(click)="confirmation()"-->
	<!--	>-->
	<!--		Confirm-->
	<!--	</button>-->
</div>
