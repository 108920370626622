<div class="quiz">
	<div class="welcome" *ngIf="welcome">
		<div class="welcome-header">
			<div class="wrapper-content">
				<div class="">
					<figure class="logo">
						<img
							src="/assets/images/logo.png"
							draggable="false"
							alt="Clínica Rascovski"
							title="Clínica Rascovski"
						/>
					</figure>
					<figure>
						<img
							src="/assets/images/clinica-rascovski.png"
							draggable="false"
							alt="Clínica Rascovski"
							title="Clínica Rascovski"
						/>
					</figure>
				</div>
			</div>
		</div>
		<div class="wrapper-content">
			<div class="message">
				<p>
					<strong>Olá!</strong> Você foi convidado a participar do
					programa de avaliação de bem-estar conduzido pela clínica
					Rascovski.
				</p>
				<p>
					A sua privacidade é nossa prioridade por isso nenhum dado
					que possa identifica-lo será compartilhado
				</p>
			</div>
			<button
				type="text"
				class="btn btn-gold btn-shadow"
				(click)="setPreload()"
			>
				Continuar
			</button>
			<a href="https://www.clinicarascovski.com.br/" target="_blank"
				>Conheça mais sobre a clínica</a
			>
		</div>
	</div>
	<div class="feedback" *ngIf="feedBack && initFeedBack">
		<app-feedback
			(emmitEventFeedback)="sendFeedback($event)"
			*ngIf="feedBack"
			[formValue]="feedBack"
		></app-feedback>
	</div>
	<div class="header">
		<div class="wrapper-content">
			<div class="d-flex align-items-center gap-4">
				<figure class="logo">
					<img
						src="/assets/images/logo.png"
						draggable="false"
						alt="Clínica Rascovski"
						title="Clínica Rascovski"
					/>
				</figure>
				<figure>
					<img
						src="/assets/images/clinica-rascovski.png"
						draggable="false"
						alt="Clínica Rascovski"
						title="Clínica Rascovski"
					/>
				</figure>
			</div>
		</div>
	</div>
	<div class="w-100 m-0">
		<div class="row m-0" *ngIf="!currentPillar && !finished">
			<div class="question col-12 px-6 pb-12">
				<h3 class="mb-10">Informações pessoais</h3>
				<ng-container *ngIf="!loadingPersonalData && _reload">
					<div class="question-content">
						<app-personal-data-form
							[initialValue]="
								formPillarsData ? formPillarsData.data : null
							"
							(emmitEventForm)="savePersonalData($event)"
						></app-personal-data-form>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="wrapper-response p-0" *ngIf="currentPillar && _reload">
			<div class="m-0">
				<app-board
					*ngIf="answerQuestionnaire"
					[title]="currentPillar.title"
					[description]="currentPillar.description"
					btnTitle="Continuar"
					(emmitEventClick)="setQuestionnaire()"
				>
				</app-board>

				<div class="question aside-form">
					<div class="d-flex flex-wrap">
						<h3 class="px-4">
							{{ currentPillar.title }}
							<span
								>{{ currentPillar.field.length }} questões</span
							>
						</h3>
						<div class="wrapper-tabs px-4 pt-4">
							<span
								*ngFor="
									let pillar of currentPillar.field;
									let i = index
								"
								[class.active]="currentQuestion >= i + 1"
							></span>
						</div>
					</div>

					<app-form-builder
						(onSubmitForm)="onSubmit($event)"
						(backPage)="backPage($event)"
						(nextPage)="nextPage($event)"
						[currentQuestion]="currentStep"
						[formJson]="currentPillar.field"
					>
					</app-form-builder>
				</div>
			</div>
		</div>
		<div class="finish" *ngIf="finished">
			<app-board>
				<div content class="text-center">
					<figure class="mb-10 pt-16">
						<img
							src="./assets/images/check.svg"
							alt="Obrigado por responder o questionário"
							title="Obrigado por responder o questionário"
						/>
					</figure>
					<h3>{{ messageFinished }}</h3>
					<a
						routerLink="/home"
						type="button"
						class="btn btn-gold shadow mt-10 mt-md-20"
					>
						Ir para o início
					</a>
				</div>
			</app-board>
		</div>
	</div>
</div>
