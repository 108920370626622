<div class="form-group" *ngIf="forms.length">
	<div *ngFor="let f of forms" class="wrapper-form custom-scroll">
		<form
			novalidate
			(ngSubmit)="onSubmit(f.formGroup)"
			[formGroup]="f.formGroup"
		>
			<ng-container *ngFor="let field of f.metaData; let i = index">
				<div class="wrapper-content" *ngIf="currentQuestion === i + 1">
					<app-controls-form-builder
						[prop]="field"
						[name]="field.id"
						[form]="f.formGroup"
						(emmitEventRadios)="sendValueInput($event)"
					>
					</app-controls-form-builder>
					<div
						class="wrapper-buttons gap-4"
						*ngIf="currentQuestion === i + 1"
					>
						<button
							type="button"
							class="back"
							*ngIf="currentQuestion > 1"
							(click)="backStepQuestion()"
						>
							Voltar
						</button>
						<button
							type="button"
							class="next"
							*ngIf="hasNextStep > 0"
							(click)="nextStepQuestion()"
						>
							Avançar
						</button>
					</div>
				</div>
			</ng-container>
		</form>
	</div>
</div>
