import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { AppState } from '../../../state/app-state'
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-modal-default',
	templateUrl: './modal-form.component.html',
	styleUrls: ['./modal-form.component.scss'],
})
export class ModalFormComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() id?: string
	@Input() title?: string
	@Input() desc?: string
	@Input() initialData: any

	constructor(
		public activeModal: NgbActiveModal,
		private store: Store<AppState>,
		private toast: ToastrService
	) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		// if (this.callbackConfirmation) this.callbackConfirmation()
		// this.close()
	}

	submit(el: any) {
		if (this.callbackConfirmation) this.callbackConfirmation(el)
	}
}
