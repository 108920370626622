<form action="#" autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="form">
	<div>
		<app-input
			id="name"
			name="name"
			typeInput="text"
			labelName="Nome completo"
			placeholder="Nome"
			[form]="form"
			[inputModel]="initialValue ? initialValue.name : ''"
		></app-input>
		<app-input
			id="email"
			name="email"
			typeInput="text"
			labelName="E-mail"
			placeholder="E-mail"
			[form]="form"
			[inputModel]="initialValue ? initialValue.email : ''"
		></app-input>
		<div class="row">
			<div class="col-md-6">
				<app-input
					id="cpf"
					name="cpf"
					typeInput="text"
					labelName="CPF"
					placeholder="CPF"
					inputMask="000.000.000-00"
					[form]="form"
					[inputModel]="initialValue ? initialValue.cpf : ''"
				></app-input>
			</div>
			<div class="col-md-6">
				<app-input
					id="phone"
					name="phone"
					typeInput="text"
					labelName="Telefone"
					placeholder="Telefone"
					inputMask="(00) 0000-0000||(00) 0 0000-0000"
					[form]="form"
					[inputModel]="initialValue ? initialValue.phone : ''"
				></app-input>
			</div>
		</div>
	</div>
	<div class="wrapper-button d-flex w-100 pb-5">
		<button
			type="submit"
			class="btn btn-gold shadow"
			[disabled]="form.invalid || hasPersonalId"
			id="continue-form-journey"
		>
			Continuar
		</button>
	</div>
</form>
