import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'
import { Event, NavigationEnd, Route, Router } from '@angular/router'

@Component({
	selector: 'app-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
	titlePage: string = ''
	minimizeAside: boolean = false
	showMenu: boolean = false
	animating: boolean = false
	isMobile: boolean = true

	constructor(private authService: AuthService, private router: Router) {}

	ngOnInit(): void {
		this.initWindow()
		this.getTitlePage()
		this.watchTitlePage()
	}

	initWindow(evt?: any): void {
		this.isMobile = evt?.currentTarget?.innerWidth <= 992
	}

	toggleMinimizeAside(): void {
		this.minimizeAside = !this.minimizeAside

		if (this.minimizeAside) {
			this.animating = true

			setTimeout(() => {
				this.animating = false
			}, 250)
		}
	}

	toggleMenu(): void {
		this.showMenu = !this.showMenu
	}

	watchTitlePage(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.getTitlePage()
			}
		})
	}

	private getTitlePage() {
		const { config } = this.router
		let currentRoute: Route | undefined

		config.forEach((item) => {
			if (item.children?.length) {
				item.children.filter((subItem) => {
					if (
						`${item.path}/${subItem.path}` ===
						this.router.url.replace('/', '')
					) {
						currentRoute = subItem
					}
				})
			}
			if (item.path === this.router.url.replace('/', '')) {
				currentRoute = item
			}
		})
		if (currentRoute?.data) {
			this.titlePage = currentRoute?.data.title
		}
	}
}
