<div class="board">
	<div class="wrapper-figure text-center mt-5">
		<figure class="logo mb-3">
			<img
				src="/assets/images/logo.png"
				draggable="false"
				alt="Clínica Rascovski"
				title="Clínica Rascovski"
			/>
		</figure>
		<figure>
			<img
				src="/assets/images/clinica-rascovski.png"
				draggable="false"
				alt="Clínica Rascovski"
				title="Clínica Rascovski"
			/>
		</figure>
	</div>
	<div class="card-content">
		<div *ngIf="title && description">
			<h2>{{ title }}</h2>
			<h3>
				{{ description }}
			</h3>
		</div>
		<ng-content select="[content]"></ng-content>
		<div
			class="wrapper-button d-flex justify-content-end pt-10 pt-md-20 mt-md-20"
			*ngIf="btnTitle"
		>
			<button
				type="button"
				class="btn btn-gold shadow"
				(click)="setQuestionnaire()"
			>
				{{ btnTitle }}
			</button>
		</div>
	</div>
</div>
