import { Component, OnInit } from '@angular/core'
import { Pillars } from '../../models/pillars'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder } from '@angular/forms'
import { HelpersService } from '../../services/helpers/helpers.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { JourneyService } from '../../services/journey/journey.service'
import { Journey } from '../../models/journey'
import { ModalStatusComponent } from '../../components/modals/modal-status/modal-status.component'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'

@Component({
	selector: 'app-quiz',
	templateUrl: './journey.component.html',
	styleUrls: ['./journey.component.scss'],
})
export class JourneyComponent implements OnInit {
	journey: Journey
	formPillarsData: any = null

	code: string = this.route.snapshot.params.id

	currentPillar: Pillars | null = null
	currentPillarId?: any = ''
	feedBack?: any = ''
	initFeedBack?: boolean = false

	loading: boolean = false
	loadingPersonalData: boolean = false

	_reload: boolean = true
	welcome: boolean = true
	answerQuestionnaire: boolean = true
	finished: boolean = false
	messageFinished: string = 'Obrigado por responder o questionário'

	currentStep: number = 1
	currentQuestion: number = 1
	percentPillar: number = 0
	currentQuestionSelected: number = 0

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private helpers: HelpersService,
		private toast: ToastrService,
		private modalQuiz: NgbModal,
		private journeyService: JourneyService,
		private modalDefault: NgbModal,
		private store: Store<AppState>
	) {
		this.journey = {
			id: this.route.snapshot.params.id,
			slug: '',
			title: '',
			pillars: [],
			version: '',
			personalId: '',
			personalData: {
				document: '',
			},
		}
	}

	ngOnInit(): void {
		this.getJourney(this.code)
	}

	getJourney(id: string): void {
		const self = this

		self.journeyService.getJourney(id, {
			fnSuccess(response) {
				self.feedBack = response.data.profile[0].pillar
				const pillarsFiltered = response.data.profile[0].pillar.filter(
					(pillar: any) => {
						return Number(pillar.percent) < 100
					}
				)
				const profile = {
					...response.data.profile[0],
					pillar: pillarsFiltered,
				}
				self.formPillarsData = {
					...response,
					data: { ...response.data, profile: [profile] },
				}

				self.currentQuestionSelected = 0

				if (pillarsFiltered.length === 0) {
					self.finished = true
					self.messageFinished =
						'Você concluiu este questionário, obrigado.'
				}

				if (
					self.formPillarsData.data.name &&
					self.formPillarsData.data.email &&
					self.formPillarsData.data.cpf &&
					self.formPillarsData.data.phone
				) {
					self.setActiveQuestion(self.currentQuestionSelected)
				} else {
					self.welcome = true
				}
			},
			fnFinalized() {
				self.loading = false
				self.loadingPersonalData = false
			},
			fnError() {
				self.openModal('Ops!', 'Algo deu errado!', 'error')
			},
		})
	}

	savePersonalData(data: any): void {
		const self = this
		self.toggleLoading('show')

		self.journeyService.saveDataUser(this.code, data, {
			fnSuccess() {
				self.getJourney(self.code)
			},
			fnFinalized() {
				self.toggleLoading('hide')
			},
			fnError() {
				self.openModal('Ops!', 'Algo deu errado!', 'error')
			},
		})
	}

	onSubmit(event: any) {
		const self = this
		const data = {
			code: this.formPillarsData.data.code,
			profile_id: this.formPillarsData.data.profile[0].id,
			pillar_id: this.currentPillarId.pillarId,
			percent: this.percentPillar.toFixed(2),
			field: {
				id: event.id,
				value: event.event,
			},
		}

		self.journeyService.saveDataField(data, {
			fnSuccess() {
				const count = self.formPillarsData.data.profile[0].pillar.length
				if (self.percentPillar === 100) {
					self.getJourney(self.code)
					self.currentStep = 1
					self.answerQuestionnaire = true
					console.log(count, self.currentQuestionSelected)
					if (count === self.currentQuestionSelected + 1) {
						self.initFeedBack = true
					}
					self.currentQuestionSelected =
						self.currentQuestionSelected + 1
					self.setActiveQuestion(self.currentQuestionSelected)
				} else {
					self.currentStep = self.currentStep + 1
				}
				self.setPercentInPillar()

				self.currentQuestion = self.currentStep
				window.scrollTo({ top: 0, behavior: 'smooth' })
			},
			fnFinalized() {
				self.loading = false
			},
			fnError() {
				self.openModal('Ops!', 'Algo deu errado!', 'error')
			},
		})
	}

	backPage(event: any) {
		this.currentQuestion = event
		this.currentStep = event
		this.setPercentInPillarBack()
	}

	nextPage(event: any) {
		this.currentQuestion = event
		this.currentStep = event
		this.setPercentInPillar()
	}

	setCurrentPillar(pillar: Pillars | null): void {
		this.currentPillar = pillar

		this.currentPillarId = {
			pillarId: this.currentPillar?.id,
			percent: this.currentPillar?.percent,
		}

		this.percentPillar = 0
		this.setPercentInPillar()

		this.reload()
	}

	setPercentInPillar() {
		if (this.currentPillar) {
			const percent = 100 / this.currentPillar?.field.length
			if (this.percentPillar < 100) {
				this.percentPillar = this.percentPillar + percent
			}
		}
	}

	setPercentInPillarBack() {
		if (this.currentPillar) {
			const percent = 100 / this.currentPillar?.field.length
			if (this.percentPillar > 0) {
				this.percentPillar = this.percentPillar - percent
			}
		}
	}

	openModal(
		title: string,
		desc: string,
		type: string,
		btn: string = 'Continuar',
		idForm: string = ''
	) {
		const modalRef = this.modalDefault.open(ModalStatusComponent)
		modalRef.componentInstance.title = title
		modalRef.componentInstance.desc = desc
		modalRef.componentInstance.type = type
		modalRef.componentInstance.btnName = btn
		modalRef.componentInstance.idForm = idForm
	}

	setPreload() {
		this.welcome = false
	}
	setQuestionnaire() {
		this.answerQuestionnaire = false
	}

	setActiveQuestion(question: number) {
		const data = this.formPillarsData.data.profile[0].pillar[question]
		this.setCurrentPillar(data)
	}

	reload() {
		setTimeout(() => (this._reload = false))
		setTimeout(() => (this._reload = true))
	}

	sendFeedback(event: any) {
		console.log(event)
		const self = this

		self.journeyService.sendFeedback(this.code, event, {
			fnSuccess() {
				self.finished = true
				self.initFeedBack = false
				self.messageFinished =
					'Você concluiu este questionário, obrigado.'
			},
			fnError(error) {
				console.log(error)
			},
		})
	}

	toggleLoading(type: string) {
		setTimeout(() => {
			this.store.dispatch(
				type == 'show' ? ShowLoading() : HiddenLoading()
			)
		})
	}
}
