const api = 'https://rascovski-back-v2.valide.vc/api/response'

export const environment = {
	api: `${api}`,
	redirects: {
		roles: {
			admin: '/dashboard',
			public: '/',
		},
	},
	env: 'hml',
	production: false,
}
