<div
	class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
	style="--kt-toolbar-height: 0px; --kt-toolbar-height-tablet-and-mobile: 0px"
	[attr.data-kt-aside-minimize]="minimizeAside ? 'on' : 'off'"
	[attr.data-kt-drawer]="showMenu ? 'on' : 'off'"
	(window:resize)="initWindow($event)"
	(window:DOMContentLoaded)="initWindow($event)"
>
	<div class="d-flex flex-column flex-root">
		<div class="page d-flex flex-row flex-column-fluid">
			<div
				[class]="
					'aside aside-hoverable ' +
					(animating ? 'animating ' : '') +
					(minimizeAside ? 'drawer drawer-start drawer-on ' : '') +
					(showMenu ? 'drawer-on ' : '') +
					(isMobile ? 'drawer drawer-start' : '')
				"
				data-kt-drawer="true"
				data-kt-drawer-name="aside"
				data-kt-drawer-activate="{default: true, lg: false}"
				data-kt-drawer-overlay="true"
				data-kt-drawer-width="{default:'200px', '300px': '250px'}"
				data-kt-drawer-direction="start"
				data-kt-drawer-toggle="#kt_aside_mobile_toggle"
			>
				<div class="aside-logo flex-column-auto">
					<a routerLink="/home">
						<picture>
							<source
								media="(max-width: 992px)"
								srcset="assets/images/logo_ghr.png"
							/>
							<img
								alt="Logo"
								src="assets/images/logo_ghr.png"
								class="logo h-70px"
								[class]="
									minimizeAside ? 'aside-logo-compacted' : ''
								"
							/>
						</picture>
					</a>
				</div>
				<div class="aside-menu flex-column-fluid">
					<div class="my-5 my-lg-5">
						<div
							class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-bullet-primary menu-arrow-gray-500"
							data-kt-menu="true"
						>
							<!-- <app-label-group-menu-item
								title="Sistema"
							></app-label-group-menu-item> -->
<!--							<app-menu-item-->
<!--								class="bright"-->
<!--								*ngFor="let item of menuSystem"-->
<!--								[title]="item.title"-->
<!--								[link]="item.link"-->
<!--								[icon]="item.icon"-->
<!--								[subItems]="item.subItems"-->
<!--							></app-menu-item>-->
						</div>
					</div>
				</div>
			</div>
			<div class="wrapper d-flex flex-column flex-row-fluid">
				<div class="header align-items-stretch">
					<div
						class="container-fluid d-flex align-items-stretch justify-content-between"
					>
						<div
							class="d-flex align-items-center d-lg-none ms-n3 me-1"
							title="Show aside menu"
						>
							<div
								class="btn btn-icon w-30px h-30px w-md-40px h-md-40px"
								(click)="toggleMenu()"
							>
								<span class="svg-icon svg-icon-2x mt-1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										width="24px"
										height="24px"
										viewBox="0 0 24 24"
										version="1.1"
									>
										<g
											stroke="none"
											stroke-width="1"
											fill="none"
											fill-rule="evenodd"
										>
											<rect
												x="0"
												y="0"
												width="24"
												height="24"
											/>
											<rect
												fill="#000000"
												x="4"
												y="5"
												width="16"
												height="3"
												rx="1.5"
											/>
											<path
												d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
												fill="#000000"
												opacity="0.3"
											/>
										</g>
									</svg>
								</span>
							</div>
						</div>
						<div
							class="d-flex align-items-center flex-grow-1 flex-lg-grow-0"
						>
							<a routerLink="#" class="d-lg-none">
								<picture>
									<source
										media="(max-width: 992px)"
										srcset="
											assets/images/transparent-ghr-logo.png
										"
									/>
									<img
										alt="Logo"
										src="assets/images/logo_ghr.png"
										class="h-30px"
									/>
								</picture>
							</a>
						</div>
						<div
							class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
						>
							<div class="d-flex align-items-stretch">
								<div
									class="header-menu align-items-stretch"
									data-kt-drawer="true"
									data-kt-drawer-name="header-menu"
									data-kt-drawer-activate="{default: true, lg: false}"
									data-kt-drawer-overlay="true"
									data-kt-drawer-width="{default:'200px', '300px': '250px'}"
									data-kt-drawer-direction="end"
									data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
									data-kt-swapper="true"
									data-kt-swapper-mode="prepend"
									data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
								>
									<div
										class="d-flex justify-content-between align-items-center new-nav"
										[class]="
											minimizeAside
												? 'new-nav-off '
												: 'new-nav-on'
										"
									>
										<div
											[class]="
												'btn btn-icon w-auto px-0 aside-toggle ' +
												(minimizeAside ? 'active' : '')
											"
											(click)="toggleMinimizeAside()"
										>
											<span class="svg-icon svg-icon-1">
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M2 5C2 4.44771 2.44771 4 3 4H17C17.5523 4 18 4.44771 18 5C18 5.55228 17.5523 6 17 6H3C2.44771 6 2 5.55228 2 5ZM2 15C2 14.4477 2.44772 14 3 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H3C2.44771 16 2 15.5523 2 15ZM3 9C2.44771 9 2 9.44772 2 10C2 10.5523 2.44771 11 3 11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H3Z"
														fill="#3F434A"
													/>
												</svg>
											</span>
										</div>
										<div>&nbsp;</div>
<!--										<app-navbar></app-navbar>-->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content d-flex flex-column flex-column-fluid">
					<div class="container-fluid">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="showMenu"
	(click)="toggleMenu()"
	style="z-index: 109"
	class="drawer-overlay"
></div>
